/**
 * Creates a new byteArray of the same type (Uint8Array or Buffer) of the specified length.
 * @param byteArray the underlying byteArray (either Uint8Array or Buffer)
 * @param length number of bytes of the Byte Array
 * @returns {object} Uint8Array or Buffer depending on the type of byteArray
 */
export default function alloc (byteArray, length) {
  if (typeof Buffer !== 'undefined' && byteArray instanceof Buffer) {
    return Buffer.alloc(length);
  } else if (byteArray instanceof Uint8Array) {
    return new Uint8Array(length);
  }
  throw 'dicomParser.alloc: unknown type for byteArray';
}
