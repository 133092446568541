(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@cornerstonejs/core"), require("dicom-parser"));
	else if(typeof define === 'function' && define.amd)
		define("cornerstoneDICOMImageLoader", ["@cornerstonejs/core", "dicom-parser"], factory);
	else if(typeof exports === 'object')
		exports["cornerstoneDICOMImageLoader"] = factory(require("@cornerstonejs/core"), require("dicom-parser"));
	else
		root["cornerstoneDICOMImageLoader"] = factory(root["@cornerstonejs/core"], root["dicomParser"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__464__, __WEBPACK_EXTERNAL_MODULE__713__) => {
return 